import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Grid, Link, Container, Typography, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Logo from '../../components/Logo';
import SocialButtonsCPO from '../../components/SocialButtonsCPO';
import { varFade, MotionViewport } from '../../components/animate';
import PrivacyPolicy from './PrivacyPolicy';
import { PATH_PAGE } from '../../routes/paths';
import { cmsAPI } from '../../services/cms.service';
import Image from '../../components/Image';

const RootStyle = styled('div')(() => ({
  position: 'relative',
  backgroundColor: '#9EBFE0',
}));

const MainFooter = () => {
  const [footerDetails, setFooterDetails] = useState({});
  const [socialDetails, setSocialDetails] = useState({});
  const [openPrivacy, setOpenPrivacy] = useState(false);

  useEffect(() => {
    const getFooter = async () => {
      const { footer, contactus } = await cmsAPI.getFooterDetails();

      setFooterDetails({
        ...footer?.data,
        mission: "Bridging Donors to Worthy Causes",
        accreditation:
          "The 101 Foundation Inc. operating under the name of CharityPhilippines.org is duly registered with and licensed by DSWD to operate as an Auxiliary Social Welfare and Development Agency.",
        aboutUs: { text: "About Us", link: PATH_PAGE.about },
        contactUs: { text: "Contact Us", link: PATH_PAGE.contact },
        dswd: { text: "DSWD", link: "https://www.dswd.gov.ph/" },
      });

      setSocialDetails(contactus?.data || {});
    };

    getFooter();
  }, []);

  return (
    <RootStyle>
      <Container sx={{ pt: 3, pb: 2 }} component={MotionViewport}>
        <m.div variants={varFade().inUp}>
          <Grid
            container
            justifyContent={{ xs: 'center', md: 'space-between' }}
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            {/* Left Section - Logo & Mission */}
            <Grid item xs={8} md={4}>
              <Box sx={{ mb: 1 }}>
                <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                  {footerDetails?.mission}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                {footerDetails?.address?.split(/\\n|\n/g).map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </Grid>

            {/* Center Section - Social & Links */}
            <Grid item xs={8} md={4} display="flex" justifyContent="center">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
              

                {/* Links */}
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: { xs: 5, md: 2 } }}
                >
                  <Link
                    href={footerDetails?.aboutUs?.link}
                    color="inherit"
                    variant="button"
                    sx={{ mr: { xs: 0, md: 3 }, textAlign: 'center' }}
                  >
                    {footerDetails?.aboutUs?.text}
                  </Link>
                  <Link
                    href={footerDetails?.contactUs?.link}
                    color="inherit"
                    variant="button"
                    sx={{ textAlign: 'center' }}
                  >
                    {footerDetails?.contactUs?.text}
                  </Link>
                </Stack>
  {/* Social Buttons */}
                <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                  <SocialButtonsCPO socialLink={socialDetails} socialColor="#000" />
                </Stack>
                {/* Copyright */}
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '.70rem',
                      textAlign: 'center',
                      mt: {xs:5, md:3},
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {footerDetails?.copyright?.replace(/\\n/g, "\n")}
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            {/* Right Section - DSWD Accreditation  */}
            <Grid
              item
              xs={8}
              md={4}
              sx={{
                textAlign: { xs: 'center', md: 'right' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'flex-end' },
                justifyContent: 'flex-end', 
                mt:{xs:5, md:0}
              }}
            >
              <Grid container spacing={1} justifyContent={{ xs: 'center', md: 'flex-end' }}>
                {footerDetails?.accreditedUrlImages?.map((e, i) => (
                  <Grid key={`aui${i}`} item>
                    <a href={footerDetails?.dswd?.link} target="_blank" rel="noopener noreferrer">
                      <Image sx={{ height: 55 }} src={e} />
                    </a>
                  </Grid>
                ))}
              </Grid>

              <Typography
                component="p"
                variant="body2"
                sx={{
                  textAlign: { xs: 'center', md: 'right' }, 
                  mt: { xs: 2, md: 1 },
                  fontSize: '.85rem',
                  pl: { xs: 0, md: 10 },
                }}
              >
                <span style={{ marginLeft: '4px' }}>{footerDetails?.accreditation}</span>
              </Typography>
            </Grid>
          </Grid>
        </m.div>

        {/* Privacy Policy */}
        <Box sx={{ mt: 0, pb: 2, width: 1, display: 'flex', justifyContent: 'center' }}>
          <PrivacyPolicy open={openPrivacy} handleClose={() => setOpenPrivacy(false)} />
        </Box>
      </Container>
    </RootStyle>
  );
};

export default MainFooter;
