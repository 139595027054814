import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Link, Stack, Tooltip, IconButton } from '@mui/material';
import Image from './Image'; // Assuming you have an Image component for handling images

SocialButtonsCPO.propTypes = {
  socialColor: PropTypes.string,
  socialLink: PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    twitter: PropTypes.string,
    tiktok: PropTypes.string,
    linked: PropTypes.string,
  }),
};

export default function SocialButtonsCPO({ socialColor, socialLink }) {
  const SOCIALS = [
    { name: 'Facebook', img: './icons/facebook-icon.png', path: socialLink?.facebook },
    { name: 'Instagram', img: './icons/instagram-icon.png', path: socialLink?.instagram },
    { name: 'Twitter', img: './icons/x-icon.png', path: socialLink?.twitter },
    { name: 'Tiktok', img: './icons/tiktok-icon.png', path: socialLink?.tiktok },
    { name: 'LinkedIn', img: './icons/linkedin-icon.png', path: socialLink?.linked },
  ];

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center">
      {SOCIALS.map(({ name, img, path }) => (
        <Tooltip key={name} title={name} placement="top">
          {path ? (
            <Link href={path} target="_blank" rel="noopener noreferrer">
              <IconButton
                sx={{
                  mr: 1.2,
                  p: 0,
                  color: socialColor,
                  '&:hover': { bgcolor: alpha(socialColor, 0.08) },
                }}
              >
                <Image src={img} alt={name} sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Link>
          ) : (
            <IconButton
              disableRipple
              sx={{
                mr: 1.2,
                p: 0,
                cursor: 'default',
                color: socialColor,
              }}
            >
              <Image src={img} alt={name} sx={{ width: 32, height: 32, opacity: 0.5 }} />
            </IconButton>
          )}
        </Tooltip>
      ))}
    </Stack>
  );
}
