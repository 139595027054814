import axios from 'axios';
import { CPO_API } from '../config';

// const API_BASE_URL = "https://prod-api.charityphilippines.org"; // "https://dev-api.charityphilippines.org";
const API_BASE_URL = CPO_API; // "https://dev-api.charityphilippines.org";
const submitInquiry = (payload) => axios.post(`${API_BASE_URL}/email/contactUs`,payload)
const sendGiftCard = (payload) => axios.post(`${API_BASE_URL}/email/giftCard`,payload)

export const ContactUSAPI = {
  submitInquiry,
  sendGiftCard,
}