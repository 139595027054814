const GIFT_CARDS = {
    birthday: {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAFqIx_wFyk-yellow-and-green-floral-photo-birthday-card/',
                preview: '/images/gift-cards/birthday-card-1.png',
                value: 'birthday-card-1',
            },
            {
                url: 'http://canva.com/templates/EAF4_g6epZ0-birthday-friend-card-in-green-and-gold-freeform-illustrations-style/',
                preview: '/images/gift-cards/birthday-card-2.png',
                value: 'birthday-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAFonk4pWlU-pink-watercolor-floral-happy-birthday-greeting-card/',
                preview: '/images/gift-cards/birthday-card-3.png',
                value: 'birthday-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAGF_D9bZzo-colorful-cute-geometric-shapes-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-4.png',
                value: 'birthday-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAGLmxTqhIA-pink-watercolor-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-5.png',
                value: 'birthday-card-5',
            },
            {
                url: 'https://www.canva.com/templates/EAGUBvFQRpo-white-and-pink-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-6.png',
                value: 'birthday-card-6',
            },
            {
                url: 'https://www.canva.com/templates/EAFvq0iTsHA-beige-illustrated-greeting-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-7.png',
                value: 'birthday-card-7',
            },
            {
                url: 'https://www.canva.com/templates/EAFgxaP2Vis-white-illustrated-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-8.png',
                value: 'birthday-card-8',
            },
            {
                url: 'https://www.canva.com/templates/EAFwYcOdq-g-white-and-gold-simple-greeting-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-9.png',
                value: 'birthday-card-9',
            },
            {
                url: 'https://www.canva.com/templates/EAF0qiSi-YM-blue-navy-and-gold-elegant-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-10.png',
                value: 'birthday-card-10',
            },
            {
                url: 'https://www.canva.com/templates/EAF0qiSi-YM-blue-navy-and-gold-elegant-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-11.png',
                value: 'birthday-card-11',
            },
            {
                url: 'https://www.canva.com/templates/EAF0qiSi-YM-blue-navy-and-gold-elegant-happy-birthday-card/',
                preview: '/images/gift-cards/birthday-card-12.png',
                value: 'birthday-card-12',
            },
        ],
        defaultMessage: "Wishing you a day filled with love, joy, and laughter. Happy Birthday! 🎉🎊",

    },
    anniversary:
    {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAE-hrgcS3w-happy-anniversary-card-landscape-/',
                preview: '/images/gift-cards/anniversary-card-1.png',
                value: 'anniversary-card-1',
            },
            {
                url: 'https://www.canva.com/templates/EAF5NmTlr4Q-brown-and-beige-floral-happy-anniversary-card/',
                preview: '/images/gift-cards/anniversary-card-2.png',
                value: 'anniversary-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAGHs9SpIKM-red-beige-minimalist-happy-anniversary-card/',
                preview: '/images/gift-cards/anniversary-card-3.png',
                value: 'anniversary-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAGVM7Xxz-E-white-and-brown-minimalist-photo-anniversary-card/',
                preview: '/images/gift-cards/anniversary-card-4.png',
                value: 'anniversary-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAFYgLwgRsE-brown-aesthetic-happy-anniversary-card/',
                preview: '/images/gift-cards/anniversary-card-5.png',
                value: 'anniversary-card-5',
            },
            {
                url: 'https://www.canva.com/templates/EAFYgLwgRsE-brown-aesthetic-happy-anniversary-card/',
                preview: '/images/gift-cards/anniversary-card-6.png',
                value: 'anniversary-card-6',
            },
            {
                url: 'https://www.canva.com/templates/EAFYgLwgRsE-brown-aesthetic-happy-anniversary-card/',
                preview: '/images/gift-cards/anniversary-card-7.png',
                value: 'anniversary-card-7',
            },
            {
                url: 'https://www.canva.com/templates/EAFYgLwgRsE-brown-aesthetic-happy-anniversary-card/',
                preview: '/images/gift-cards/anniversary-card-8.png',
                value: 'anniversary-card-8',
            },
        ],
        defaultMessage: "Another year, another beautiful chapter together. Happy Anniversary! 🥂💑"
    },
    valentines:
    {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAF6nUx6qO0-pink-modern-scrapbook-valentine-s-day-greeting-card/',
                preview: '/images/gift-cards/valentines-card-1.png',
                value: 'valentines-card-1',
            },
            {
                url: 'https://www.canva.com/templates/EAFYCG8z_R4-pink-minimal-watercolor-happy-valentines-card/',
                preview: '/images/gift-cards/valentines-card-2.png',
                value: 'valentines-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAF6g078sCY-red-and-pink-modern-scrapbook-valentine-s-day-card/',
                preview: '/images/gift-cards/valentines-card-3.png',
                value: 'valentines-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAF8DMMvoNg-pink-pastel-simple-minimalist-valentine-s-day-card/',
                preview: '/images/gift-cards/valentines-card-4.png',
                value: 'valentines-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAGZpj50rb8-pink-illustration-happy-valentines-greeting-card/',
                preview: '/images/gift-cards/valentines-card-5.png',
                value: 'valentines-card-5',
            },
            {
                url: 'https://www.canva.com/templates/EAGZpj50rb8-pink-illustration-happy-valentines-greeting-card/',
                preview: '/images/gift-cards/valentines-card-6.png',
                value: 'valentines-card-6',
            },
            {
                url: 'https://www.canva.com/templates/EAGZpj50rb8-pink-illustration-happy-valentines-greeting-card/',
                preview: '/images/gift-cards/valentines-card-7.png',
                value: 'valentines-card-7',
            },
            {
                url: 'https://www.canva.com/templates/EAGZpj50rb8-pink-illustration-happy-valentines-greeting-card/',
                preview: '/images/gift-cards/valentines-card-8.png',
                value: 'valentines-card-8',
            },
        ],
        defaultMessage: "Love makes life beautiful. Wishing you a day full of love! 💘✨"
    },
    graduation: {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAGH6GUxmR4-black-and-gold-happy-graduation-greeting-card/',
                preview: '/images/gift-cards/graduation-card-1.png',
                value: 'graduation-card-1',
            },
            {
                url: 'https://www.canva.com/templates/EAGM44S5KQI-black-white-illustration-congratulations-card/',
                preview: '/images/gift-cards/graduation-card-2.png',
                value: 'graduation-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAE-TL-3qHw-brown-simple-floral-happy-graduation-card/',
                preview: '/images/gift-cards/graduation-card-3.png',
                value: 'graduation-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAGG_130QLQ-blue-and-cream-illustrative-greeting-graduation-card/',
                preview: '/images/gift-cards/graduation-card-4.png',
                value: 'graduation-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAGIk8FC15o-peach-watercolor-floral-graduation-card/',
                preview: '/images/gift-cards/graduation-card-5.png',
                value: 'graduation-card-5',
            },
            {
                url: 'https://www.canva.com/templates/EAGIk8FC15o-peach-watercolor-floral-graduation-card/',
                preview: '/images/gift-cards/graduation-card-6.png',
                value: 'graduation-card-6',
            },
            {
                url: 'https://www.canva.com/templates/EAGIk8FC15o-peach-watercolor-floral-graduation-card/',
                preview: '/images/gift-cards/graduation-card-7.png',
                value: 'graduation-card-7',
            },
            {
                url: 'https://www.canva.com/templates/EAGIk8FC15o-peach-watercolor-floral-graduation-card/',
                preview: '/images/gift-cards/graduation-card-8.png',
                value: 'graduation-card-8',
            },
        ],
        defaultMessage: "Dream big, achieve bigger. Congratulations on your graduation! 🎉🎓"
    },
    christmas: {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAGLa-KwTek-green-and-red-illustrated-merry-christmas-card/',
                preview: '/images/gift-cards/christmas-card-1.png',
                value: 'christmas-card-1',
            },
            {
                url: 'https://www.canva.com/templates/EAGLmlPY_MA-gold-and-beige-merry-christmas-greeting-card/',
                preview: '/images/gift-cards/christmas-card-2.png',
                value: 'christmas-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAGUatiGapA-brown-and-golden-happy-holidays-christmas-card/',
                preview: '/images/gift-cards/christmas-card-3.png',
                value: 'christmas-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAGLxXNtLMk-blue-and-white-merry-christmas-card/',
                preview: '/images/gift-cards/christmas-card-4.png',
                value: 'christmas-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/christmas-card-5.png',
                value: 'christmas-card-5',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/christmas-card-6.png',
                value: 'christmas-card-6',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/christmas-card-7.png',
                value: 'christmas-card-7',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/christmas-card-8.png',
                value: 'christmas-card-8',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/christmas-card-9.png',
                value: 'christmas-card-9',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/christmas-card-10.png',
                value: 'christmas-card-10',
            },
        ],
        defaultMessage: "Wishing you peace, love, and happiness this Christmas season. ❄️🎁"
    },
    mothersday: {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAGLa-KwTek-green-and-red-illustrated-merry-christmas-card/',
                preview: '/images/gift-cards/mothersday-card-1.png',
                value: 'mothersday-card-1',
            },
            {
                url: 'https://www.canva.com/templates/EAGLmlPY_MA-gold-and-beige-merry-christmas-greeting-card/',
                preview: '/images/gift-cards/mothersday-card-2.png',
                value: 'mothersday-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAGUatiGapA-brown-and-golden-happy-holidays-christmas-card/',
                preview: '/images/gift-cards/mothersday-card-3.png',
                value: 'mothersday-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAGLxXNtLMk-blue-and-white-merry-christmas-card/',
                preview: '/images/gift-cards/mothersday-card-4.png',
                value: 'mothersday-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/mothersday-card-5.png',
                value: 'mothersday-card-5',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/mothersday-card-6.png',
                value: 'mothersday-card-6',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/mothersday-card-7.png',
                value: 'mothersday-card-7',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/mothersday-card-8.png',
                value: 'mothersday-card-8',
            },
        ],
        defaultMessage: "To the world, you are a mother. To us, you are everything! 🌸💖"
    },
    fathersday: {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAGLa-KwTek-green-and-red-illustrated-merry-christmas-card/',
                preview: '/images/gift-cards/fathersday-card-1.png',
                value: 'fathersday-card-1',
            },
            {
                url: 'https://www.canva.com/templates/EAGLmlPY_MA-gold-and-beige-merry-christmas-greeting-card/',
                preview: '/images/gift-cards/fathersday-card-2.png',
                value: 'fathersday-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAGUatiGapA-brown-and-golden-happy-holidays-christmas-card/',
                preview: '/images/gift-cards/fathersday-card-3.png',
                value: 'fathersday-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAGLxXNtLMk-blue-and-white-merry-fathersday-card/',
                preview: '/images/gift-cards/fathersday-card-4.png',
                value: 'fathersday-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/fathersday-card-5.png',
                value: 'fathersday-card-5',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/fathersday-card-6.png',
                value: 'fathersday-card-6',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/fathersday-card-7.png',
                value: 'fathersday-card-7',
            },
            {
                url: 'https://www.canva.com/templates/EAF4CRj5UzA-blue-and-white-classy-and-elegant-personal-holiday-card/',
                preview: '/images/gift-cards/fathersday-card-8.png',
                value: 'fathersday-card-8',
            },
        ],
        defaultMessage: "Your strength and love inspire us every day. Happy Father’s Day! 💪❤️"
    },
    memorial: {
        designs: [
            {
                url: 'https://www.canva.com/templates/EAGYgPCBbNg-brown-cream-watercolor-memorial-funeral-card/',
                preview: '/images/gift-cards/memorial-card-1.png',
                value: 'memorial-card-1',
            },
            {
                url: 'https://www.canva.com/templates/EAGMZh9u6wQ-green-and-white-photo-illustrative-obituary-card/',
                preview: '/images/gift-cards/memorial-card-2.png',
                value: 'memorial-card-2',
            },
            {
                url: 'https://www.canva.com/templates/EAGMZ7KQz1o-purple-floral-watercolor-obituary-card/',
                preview: '/images/gift-cards/memorial-card-3.png',
                value: 'memorial-card-3',
            },
            {
                url: 'https://www.canva.com/templates/EAGMZ1HWuYY-beige-photo-elegant-classic-obituary-card/',
                preview: '/images/gift-cards/memorial-card-4.png',
                value: 'memorial-card-4',
            },
            {
                url: 'https://www.canva.com/templates/EAGMaDDEAUk-grey-and-green-floral-photo-obituary-card/',
                preview: '/images/gift-cards/memorial-card-5.png',
                value: 'memorial-card-5',
            },
        ],
        defaultMessage: "Their sacrifice will never be forgotten. Forever in our hearts. 🕊️💙"
    }
}

export default GIFT_CARDS;