import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Box, Button, Card, Checkbox, Divider, Grid, Link, Menu, MenuItem, Radio, Select, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { CausesAPI } from "../../../../services/causes.services";
import { FormProvider, RHFDatePicker, RHFTextField } from "../../../../components/hook-form";
import { countries } from "../../../../_mock";
import UploadPhoto from "../../../../sections/giving_basket/UploadPhoto";
import CardPaymentForm from "./forms/CardPaymentForm";
import NoAmountModal from "../NoAmountModal";
import BankTransferForm from "./forms/BankTransferForm";
import CheckForm from "./forms/CheckForm";
import GoodsForm from "./forms/GoodsForm";
import VolunteerForm from "./forms/VolunteerForm";

export default function DonationForm({
    discretionary,
    giverType,
    customCurrency,
    customAmount,
    projects,
    onSuccess,
    onPaymentMethodChange,
    giftCard,
    onSubmit,
    onSelectPaymentMethod,
}) {

    const [causes, setCauses] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isRecurring, setIsRecurring] = useState(false);
    const [currency, setCurrency] = useState('PHP');
    const [amount, setAmount] = useState('');
    const [selectedCauses, setSelectedCauses] = useState([]);
    const [projectsData, setProjectsData] = useState(projects);
    const [frequency, setFrequency] = useState('monthly');
    const host = process.env.PUBLIC_URL;

    const getcauses = async () => {
        const causes = await CausesAPI.getAllCauses();
        setCauses(causes.data.records);
    }

    useEffect(() => {
        if (isRecurring && paymentMethod !== 'online_payment') {
            setPaymentMethod('online_payment');
        }
    }, [isRecurring])

    useEffect(() => {
        getcauses();
    }, [])

    // FOR NON DISCRETIONARY
    useEffect(() => {
        if (customAmount) {
            setAmount(customAmount)
        }
        if (customCurrency) {
            setCurrency(customCurrency)
        }
    }, [customAmount, customCurrency])

    useEffect(() => {
        setSelectedCauses([])
        if (discretionary && giverType === 'universal') setSelectedCauses(['any']);
    }, [discretionary, giverType])

    useEffect(() => {
        if (discretionary) {
            if (amount) {
                projects[0].amount = parseFloat(amount);
            } else {
                projects[0].amount = 0;
            }
            setProjectsData([...projects]);
        }
    }, [amount])

    useEffect(() => {
        onSelectPaymentMethod?.(paymentMethod)
    }, [paymentMethod])

    const validateAmount = () => {
        const noSelectedCause = Boolean(selectedCauses.length) === false;
        if (discretionary) {
            if (giverType === 'balanced') {
                return noSelectedCause;
            }
            if (giverType === 'custom') {
                const noAmount = selectedCauses.filter(e => !e.amount);
                return noSelectedCause || Boolean(noAmount.length);
            }
        }

        return false;
    }

    const onClickDonationMode = (event, mode) => {

        setPaymentMethod(mode);

        const target = event.target;
        const parent = target.closest('.MuiDialogContent-root');
        const offset = (paymentMethod === 'goods' || paymentMethod === 'volunteer') ? 120 : 380;

        setTimeout(() => {
            parent.scrollTo({
                top: target.offsetTop + offset,
                behavior: 'smooth'
            })
        }, 200);
    }

    useEffect(() => {
        onPaymentMethodChange?.(paymentMethod);
    }, [paymentMethod])

    useEffect(() => {
        setProjectsData(projects);
    }, [projects]);

    return (
        <Box
            width='100%'
            p={2}
            mt={2}
            bgcolor='#f2f2f2'
            borderRadius={2}
        >
            <Grid container direction='column' alignItems='center' sx={{ px: { xs: 2, md: 10 } }} pt={5} spacing={2}>
                {discretionary &&
                    <>
                        {
                            giverType === 'balanced' &&
                            <Grid item alignSelf='stretch'>
                                <CauseSelector causes={causes} onChange={(e) => setSelectedCauses(e)} />
                            </Grid>
                        }
                        {
                            giverType === 'custom' &&
                            <Grid item alignSelf='stretch'>
                                <CauseSelectorWithPrice causes={causes} onChange={(e) => {
                                    setAmount(e.reduce((prev, val) => val.amount, 0));
                                    setSelectedCauses(e)
                                }} />
                            </Grid>
                        }
                        {giverType !== 'custom' &&
                            <Grid item container spacing={2} justifyContent='center'>
                                <Grid item>
                                    <Select value={currency} inputProps={{ sx: { bgcolor: 'white' } }} onChange={(e) => setCurrency(e.target.value)} >
                                        <MenuItem value="PHP" >PHP</MenuItem>
                                        <MenuItem value="USD">USD</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField value={projectsData[0].amount} fullWidth inputMode="decimal" placeholder="Enter donation amount" InputProps={{ sx: { bgcolor: 'white' } }} onChange={(e) => {
                                        const _projects = [...projectsData];
                                        _projects[0].amount = parseFloat(e.target.value || '0');
                                        setProjectsData(_projects);
                                    }} />
                                </Grid>
                            </Grid>
                        }
                    </>
                }
                <Grid item alignSelf='stretch'>
                    <Box>
                        <Grid container alignItems='center' mt={3}>
                            <Grid item xs={2} md={.5}>
                                <Checkbox checked={isRecurring} onChange={(e) => setIsRecurring(e.target.checked)} />
                            </Grid>
                            <Grid item xs={10} sx={{ cursor: 'pointer' }} onClick={() => setIsRecurring(!isRecurring)}>
                                <Typography width='100%' sx={{ wordWrap: 'break-word' }}>I want to make a greater impact by setting up a recurring donation.</Typography>
                            </Grid>
                        </Grid>
                        {
                            isRecurring ?
                                <Box mt={1} pl={5}>
                                    <Typography fontStyle='italic' fontSize={14}>Frequency:</Typography>
                                    <Select value={frequency} inputProps={{ sx: { width: { md: 300, xs: 150 }, bgcolor: 'white' } }} onChange={(e) => setFrequency(e.target.value)}>
                                        <MenuItem value="monthly" >Monthly</MenuItem>
                                        <MenuItem value="quarterly">Quarterly</MenuItem>
                                        <MenuItem value="annually">Annually</MenuItem>
                                    </Select>
                                </Box>
                                : null
                        }
                    </Box>
                </Grid>
                <Grid item mt={4} alignSelf='stretch'>
                    <Box width='100%'>
                        <Typography fontWeight={700} variant="h5">Payment</Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography>Please select payment option</Typography>
                        <Grid container mt={2} justifyContent='start' alignItems='stretch' spacing={2} flexWrap='wrap'>
                            <PaymentOption
                                imgSrc={`${host}/images/donation/DebitCreditEwallet Icon.png`}
                                label='Online Payment'
                                onClick={(e) => onClickDonationMode(e, 'online_payment')}
                                selected={paymentMethod === 'online_payment'}
                            />
                            <PaymentOption
                                imgSrc={`${host}/images/donation/Bank Transfer Icon.png`}
                                label='Bank Transfer'
                                onClick={(e) => onClickDonationMode(e, 'bank_transfer')}
                                selected={paymentMethod === 'bank_transfer'}
                                disabled={isRecurring}
                            />
                            {/* <PaymentOption
                                imgSrc={`${host}/images/donation/Check Icon.png`}
                                label='Check'
                                onClick={(e) => onClickDonationMode(e, 'check')}
                                selected={paymentMethod === 'check'}
                                disabled={isRecurring}
                            /> */}
                        </Grid>
                        {
                            !discretionary && !giftCard &&
                            <>
                                <Typography mt={5}>Donate in kind:</Typography>
                                <Grid container mt={2} justifyContent='start' alignItems='stretch' spacing={2} flexWrap='wrap'>
                                    <PaymentOption
                                        imgSrc="./images/donation/Goods Icon.png"
                                        label='Goods'
                                        onClick={(e) => onClickDonationMode(e, 'goods')}
                                        selected={paymentMethod === 'goods'}
                                        disabled={isRecurring}
                                    />
                                    <PaymentOption
                                        imgSrc="./images/donation/VolunteerService.png"
                                        label='Volunteer/Service'
                                        onClick={(e) => onClickDonationMode(e, 'volunteer')}
                                        selected={paymentMethod === 'volunteer'}
                                        disabled={isRecurring}
                                    />
                                </Grid>
                            </>
                        }


                    </Box>
                </Grid>
                <Grid id="donation-form-container" item alignSelf='stretch' width='100%'>
                    {paymentMethod === 'online_payment' && <CardPaymentForm giverType={giverType} frequency={frequency} recurring={isRecurring} projects={projectsData} causes={selectedCauses} disabled={validateAmount()} discretionary={discretionary} currency={currency} onSuccess={onSuccess} giftCard={giftCard} />}
                    {paymentMethod === 'bank_transfer' && <BankTransferForm projects={projectsData} causes={selectedCauses} disabled={validateAmount()} onSuccess={onSuccess} discretionary={discretionary} onSubmitCallback={onSubmit} />}
                    {paymentMethod === 'check' && <CheckForm projects={projectsData} causes={selectedCauses} disabled={validateAmount()} onSuccess={onSuccess} discretionary={discretionary} />}
                    {paymentMethod === 'goods' && <GoodsForm projects={projectsData} causes={selectedCauses} disabled={validateAmount()} onSuccess={onSuccess} discretionary={discretionary} />}
                    {paymentMethod === 'volunteer' && <VolunteerForm projects={projectsData} causes={selectedCauses} disabled={validateAmount()} onSuccess={onSuccess} discretionary={discretionary} />}
                </Grid>
            </Grid>
        </Box>
    )
}

function PaymentOption({
    label,
    imgSrc,
    onClick,
    selected,
    disabled
}) {
    return (
        <Grid container item xs={6} md={4} alignItems='center' justifyContent='space-between' direction='column' sx={{ cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? .3 : 1, }} onClick={onClick}>
            <Grid item xs width='100%'>
                <Box width='100%' height='100%' bgcolor='white' p={2} borderRadius={1} >
                    <Grid container item justifyContent='center' direction='column'>
                        <img src={imgSrc} alt="payment-option" style={{ objectFit: 'contain' }} height={100} />
                        <Typography align="center" mt={2} fontWeight={700} width='100%' sx={{ wordWrap: "break-word" }}>{label}</Typography>
                    </Grid>
                </Box>
            </Grid>
            <Radio checked={selected} sx={{ pointerEvents: 'none' }} />
        </Grid>
    );
}

function CauseSelector({ causes, onChange }) {

    const [selectedCauses, setSelectedCauses] = useState([]);
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    useEffect(() => {
        if (onChange) {
            onChange(selectedCauses);
        }
    }, [selectedCauses])

    return (
        <Grid container bgcolor='white' borderRadius={1} pb={2} my={5} spacing={2} alignItems='center'>
            {
                selectedCauses.map(e => (
                    <Grid key={e._id} item>
                        <Box p={1} borderRadius={1} bgcolor='#00AB55'>
                            <Grid spacing={1} container alignItems='center'>
                                <Grid item>
                                    <Typography color='white'>{e.name}</Typography>
                                </Grid>
                                <Grid item alignItems='center'>
                                    <Button sx={{ minWidth: 'unset' }} onClick={() => setSelectedCauses(selectedCauses.filter(sc => sc.name !== e.name))}>
                                        <Icon icon='mingcute:close-line' color="black" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                ))
            }
            {selectedCauses.length !== causes.length &&
                <Grid item>
                    <Button id="basic-button" variant="contained" sx={{ minWidth: 'unset', width: 40, height: 40, padding: 0 }} onClick={(e) => setAnchor(e.currentTarget)} aria-controls={open ? 'basic-menu' : undefined}>
                        <Icon icon='mingcute:add-line' fontSize={16} />
                    </Button>
                    <Menu
                        open={open}
                        anchorEl={anchor}
                        onClose={() => setAnchor(null)}
                        MenuListProps={{
                            "aria-labelledby": 'basic-button',
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: 300
                            }
                        }}
                    >
                        {causes &&
                            causes
                                .filter(c => !selectedCauses.map(sc => sc._id).includes(c._id))
                                .map(c => <MenuItem key={c._id} onClick={() => {
                                    setSelectedCauses([...selectedCauses, c])
                                    setAnchor(null)
                                }}>{c.name}</MenuItem>)
                        }
                    </Menu>
                </Grid>
            }
            {
                selectedCauses.length === 0 &&
                <Grid item>
                    <Typography color='#ccc'>Add a cause</Typography>
                </Grid>
            }
        </Grid >
    )
}

function CauseSelectorWithPrice({ causes, onChange }) {

    const [selectedCauses, setSelectedCauses] = useState([]);
    const [anchor, setAnchor] = useState(null);
    const [currency, setCurrency] = useState('PHP')
    const open = Boolean(anchor);

    useEffect(() => {
        if (onChange) onChange(selectedCauses);
    }, [selectedCauses])

    return (
        <Box>
            <Grid container spacing={2} direction='column' my={5}>
                {selectedCauses.map((e, i) =>
                    <Grid key={e._id} item container>
                        <Grid item container xs={11} borderRadius={1} overflow='hidden' border='1px solid #ddd'>
                            <Grid item xs={4} p={2} bgcolor='white'>
                                <Typography>{e.name}</Typography>
                            </Grid>
                            <Grid item container xs={8} bgcolor='#F3F8F4' alignItems='center' pl={2}>
                                <Grid item xs={1}>
                                    <Typography>{currency ?? 'PHP'}</Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField type="number" value={e.amount ?? ''} fullWidth placeholder="0.00" prefix="PHP" variant="standard" InputProps={{
                                        disableUnderline: true
                                    }} onChange={(e) => {
                                        const selected = [...selectedCauses];
                                        selected[i].amount = parseFloat(e.target.value || '0');
                                        setSelectedCauses(selected);
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} p={2} >
                            <Button sx={{ minWidth: 'unset' }} onClick={() => setSelectedCauses(selectedCauses.filter(sc => sc._id !== e._id))}>
                                <Icon icon='mingcute:close-line' color="black" />
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {

                    selectedCauses.length !== causes?.length &&
                    <Grid item container alignItems='center' spacing={2}>
                        <Grid item>
                            <Button id="basic-button" variant="contained" sx={{ minWidth: 'unset', width: 40, height: 40, padding: 0 }} onClick={(e) => setAnchor(e.currentTarget)} aria-controls={open ? 'basic-menu' : undefined}>
                                <Icon icon='mingcute:add-line' fontSize={16} />
                            </Button>
                            <Menu
                                open={open}
                                anchorEl={anchor}
                                onClose={() => setAnchor(null)}
                                MenuListProps={{
                                    "aria-labelledby": 'basic-button',
                                }}
                                PaperProps={{
                                    style: {
                                        maxHeight: 300
                                    }
                                }}
                            >
                                {causes &&
                                    causes
                                        .filter(c => !selectedCauses.map(sc => sc._id).includes(c._id))
                                        .map(cause => <MenuItem key={cause._id} onClick={() => {
                                            setSelectedCauses([...selectedCauses, cause])
                                            setAnchor(null)
                                        }}>{cause.name}</MenuItem>)
                                }
                            </Menu>
                        </Grid>
                        {
                            selectedCauses.length === 0 &&
                            <Grid item>
                                <Typography color='#ccc'>Add a cause</Typography>
                            </Grid>
                        }
                    </Grid>
                }
                <Grid container justifyContent='center' mt={5}>
                    <Grid item>
                        <Box height='100%' width='100%' bgcolor='white' p={2} border="#ccc 1px solid">
                            <Grid container justifyContent='space-around' alignItems='center' spacing={2}>
                                <Grid item>
                                    <Typography variant="h5">Total Donation Amount:</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5">
                                        <Select value={currency} inputProps={{ sx: { bgcolor: 'white' } }} onChange={(e) => setCurrency(e.target.value)} style={{ marginRight: 20 }}>
                                            <MenuItem value="PHP" >PHP</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                        </Select>
                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: "PHP" }).format(selectedCauses.reduce((prev, val, i) => prev + parseFloat(val.amount || '0'), 0)) || 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}