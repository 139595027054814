import { Box, Button, Card, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import * as yup from 'yup';
import { CloseIcon } from "../../../theme/overrides/CustomIcons";
import { useDispatch, useSelector } from "../../../redux/store";
import { removeAllProjectsEvent, removeProjectEvent, setGiftCardModal } from "../../../redux/slices/donation";
import { FormProvider, RHFSelect, RHFTextField } from "../../../components/hook-form";
import Image from "../../../components/Image";
import DonationForm from "./donation_forms/DonationForm";
import GIFT_CARDS from "../../../utils/giftcards";
import { ContactUSAPI } from '../../../services/contactus.services';
import { UploadSingleFile } from "../../../components/upload";
import { resetGiftCardDetails, setGiftCardDetails } from "../../../redux/slices/giftCard";


export default function GiftCardModal() {

  const { projects, isGiftCardOpen: open } = useSelector((store) => store.donation);
  const { giftCard } = useSelector((store) => store.giftCard);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setGiftCardModal(false))
  }

  const GiftCardSchema = yup.object().shape({
    option: yup.string().required('Please select at least one option.'),
    name: yup.string().required('Name is required.'),
    email: yup.string().email('Invalid email address.').required('Email is required.'),
    contactNumber: yup.string().required('Contact number is required.'),
  });

  const defaultValues = {
    message: "lorem ipsum dolor"
  }

  const methods = useForm({
    resolver: yupResolver(GiftCardSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    setValue,
    setError,
  } = methods;

  const values = watch();

  const sendGiftCard = (projectDonations) => {
    const donations = projectDonations.map(e => ({
      project: e.name,
      amount: e.amount,
      id: e._id,
    }));
    ContactUSAPI.sendGiftCard({ ...values, donations });
    reset({ occasion: '' })
    dispatch(removeAllProjectsEvent());
  }

  useEffect(() => {
    if (values.occasion) {
      setValue('message', GIFT_CARDS[values.occasion]?.defaultMessage);
    }
  }, [values.occasion])

  useEffect(() => {
    if (!shallowEqual(values ?? {}, giftCard ?? {}))
      dispatch(setGiftCardDetails(values))
  }, [dispatch, values])

  useEffect(() => {
    if (giftCard) {
      reset(giftCard)
    }
  }, [])

  const shallowEqual = (obj1, obj2) => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
  }

  console.log(Object.values(GIFT_CARDS).map((e) => e.designs.at(0)));

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
    >
      <DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 20,
            top: 22,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden', maxHeight: '70vh' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <Box
            component="img"
            src="/images/gift-cards/gift-card-banner.png"
            alt="Gift Card Banner"
            sx={{
              width: '80%',
              maxWidth: '80%',
              marginBottom: 2,
              mx: 'auto',
            }}
          />

          <Typography variant="h5" mb={2} textAlign="center">
            Send Gift Card
          </Typography>
          <Typography variant="body1" mb={2} textAlign="center">
            Brighten a loved one’s day with the thoughtful gesture of a gift card. Not only will it bring joy to their special moments, but every card you send also supports those in need, turning your kindness into a meaningful impact. Show your love and make a difference at the same time!
          </Typography>
        </Box>
        <Divider sx={{ mb: 5 }} />
        <FormProvider methods={methods}>

          <Typography variant="button">
            Select an occasion
          </Typography>
          <RHFSelect name="occasion" placeholder="Please select" sx={{ mt: 1 }}>
            <option value="">Please Select</option>
            <option value="birthday">Birthday</option>
            <option value="anniversary">Wedding Anniversary</option>
            <option value="valentines">Valentines</option>
            <option value="mothersday">Mother's Day</option>
            <option value="fathersday">Father's Day</option>
            <option value="graduation">Graduation</option>
            <option value="christmas">Christmas</option>

          </RHFSelect>

          {
            !values.occasion &&
            <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              {
                Object.values(GIFT_CARDS).map((e) => e.designs.at(1))
                  .map(e => <GiftCardDesignItem src={e?.preview} onClick={() => setError('occasion', { message: 'Please select an occasion', type: "validate" })} disabled />)
              }
            </Box>
          }
          {
            values.occasion &&
            <>
              <Divider sx={{ my: 3 }} />
              <Typography variant="button">
                Select Card Design
              </Typography>
              <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                {
                  GIFT_CARDS[values.occasion]?.designs?.map(e => <GiftCardDesignItem src={e.preview} onClick={() => setValue('giftCard', e.value)} selected={values.giftCard === e.value} />)
                }
              </Box>
              {
                values.giftCard &&
                <>
                  {/* <Divider sx={{ my: 2 }} />
                  <Typography variant="button">
                    Attachment
                  </Typography>
                  <UploadSingleFile sx={{ my: 2 }} name='attachment' /> */}
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="button">
                    Message
                  </Typography>
                  <RHFTextField sx={{ my: 2 }} name="message" multiline minRows={4} label="Add a message" />
                  <Grid container gap={2}>
                    <Grid item flex={1}>
                      <RHFTextField name="from" label="From" />
                    </Grid>
                    <Grid item flex={1}>
                      <RHFTextField name="fromEmail" label="Email Address" />
                    </Grid>
                  </Grid>
                  <Grid container gap={2} mt={2}>
                    <Grid item flex={1}>
                      <RHFTextField name="to" label="to" />
                    </Grid>
                    <Grid item flex={1}>
                      <RHFTextField name="toEmail" label="Email Address" />
                    </Grid>
                  </Grid>

                </>
              }
            </>
          }

        </FormProvider>
        {
          values.giftCard &&
          <>
            <Divider sx={{ my: 3 }} />
            <ProjectListSection onSubmit={sendGiftCard} giftCard={giftCard} />
          </>
        }
      </DialogContent>
    </Dialog>
  )

}

const GiftCardDesignItem = ({
  src,
  onClick,
  selected,
  disabled,
}) => {

  const [hovered, setHovered] = useState(false);

  return (
    <Box sx={{ position: 'relative', borderRadius: 2, transitionDuration: '200ms', cursor: 'pointer', boxSizing: 'border-box', '*': { boxSizing: 'border-box' } }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={onClick}>
      <Image src={src} sx={{ width: 250, height: 180, borderRadius: 2, cursor: 'pointer', border: `${(hovered || selected) && !disabled ? '2px' : '0px'} solid #00A852`, transitionDuration: '200ms' }} />
      <Box sx={{ width: "100%", height: "100%", background: "#00A852", top: 0, position: 'absolute', opacity: (hovered || selected) && !disabled ? .2 : 0, borderRadius: 2, transitionDuration: '200ms' }} />
    </Box>
  )
}

const ProjectListSection = ({ onSubmit, giftCard }) => {
  const { projects, isGiftCardOpen: open } = useSelector((store) => store.donation);
  const dispatch = useDispatch();
  const location = useLocation();
  const [showAmountFields, setShowAmountFields] = useState(true);
  const [currency, setCurrency] = useState('PHP');
  const [projectDonations, setDonations] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [instructionsOpen, setInstructionsOpen] = useState(false);

  const onAddProject = () => {
    console.log(location.pathname !== '/explore-project' && location.search !== '?ref=giftCard')
    if (location.pathname !== '/explore-project' || location.search !== '?ref=giftCard') {
      window.location.href = '/explore-project?ref=giftCard';
    } else {
      dispatch(setGiftCardModal(false));
    }
  };

  const handleCurrencyChange = (e) => {
    const selectedCurrency = e.target.value;
    if (selectedCurrency === 'USD') {
      setSnackbarOpen(true);
      setCurrency('PHP'); // Revert back to PHP
    } else {
      setCurrency(selectedCurrency);
    }
  };

  const onClose = () => {
    dispatch(setGiftCardModal(false));
  };

  const onSelectPaymentMethod = (paymentMethod) => {
    if (paymentMethod === 'online_payment') {
      // setInstructionsOpen(true);
    }
  }

  useEffect(() => {
    const json = JSON.stringify(projects);
    setDonations(JSON.parse(json));
  }, [projects])

  return (
    <Grid direction='column' gap={2}>
      <Box>
        <Typography variant="h6">Projects</Typography>
      </Box>
      {projects.length !== 0 ? (
        <Grid container direction="column" pb={5} pt={2} spacing={2}>
          {projects.map((p, i) => (
            <Grid key={p._id} item>
              <Card sx={{ padding: 3, border: '1px solid #eee' }}>
                <Grid
                  container
                  spacing={5}
                  sx={{ flexDirection: { xs: 'column', md: 'row' }, pt: { xs: 3, md: 0 } }}
                  alignItems="stretch"
                >
                  <Grid item xs={3} width="100%" maxWidth="unset">
                    <img
                      width="100%"
                      height={200}
                      src={p.mainImage}
                      alt="project-img"
                      style={{ borderRadius: 12, objectFit: 'cover' }}
                    />
                  </Grid>
                  <Grid item xs={8} container spacing={2} direction="column" justifyContent="center" maxWidth="unset">
                    <Grid item>
                      <Typography variant="h5">{p.name}</Typography>
                      <Typography variant="body2">{p.charityDetails.name}</Typography>
                    </Grid>
                    {showAmountFields && (
                      <Grid item container spacing={1} alignItems="center">
                        <Grid item xs={4}>
                          <Select
                            value={currency}
                            inputProps={{ sx: { bgcolor: 'white' } }}
                            onChange={handleCurrencyChange}
                            style={{ marginRight: 0 }}
                          >
                            <MenuItem value="PHP">PHP</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            type="number"
                            value={projectDonations[p._id]?.amount}
                            fullWidth
                            inputProps={{
                              sx: { textAlign: 'right' },
                            }}
                            placeholder="0.00"
                            onChange={(e) => {
                              const _donations = [...projectDonations];
                              _donations[i].amount = parseFloat(e.target.value || '0');
                              setDonations(_donations);
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    dispatch(removeProjectEvent(p._id));
                  }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Card>
            </Grid>
          ))}
          <Grid container justifyContent="center" mt={5}
          // mb={showAmountFields ? 5 : 10}
          >
            <Button onClick={onAddProject} variant="contained">
              ADD PROJECT
            </Button>
          </Grid>
          {showAmountFields && (
            <Grid item container justifyContent="center">
              <Grid item>
                <Box
                  height="100%"
                  width="100%"
                  minHeight="60px"
                  bgcolor="white"
                  p={2}
                  border="#ccc 1px solid"
                  sx={{ minWidth: { xs: 'unset', md: 600 } }}
                >
                  <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="h5">Total Donation Amount:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">
                        {currency}{' '}
                        {Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          currency: 'PHP',
                        }).format(
                          projectDonations.reduce((prev, val, i) => prev + parseFloat(val.amount || '0'), 0)
                        ) || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="h8">Please select at least one (1) project.</Typography>
          <Grid container justifyContent="center" my={3}>
            <Button onClick={onAddProject} variant="contained">
              ADD PROJECT
            </Button>
          </Grid>
        </Grid>
      )}

      {projects.length !== 0 && (
        <Grid mt="-90px" item>
          <DonationForm
            onSubmit={() => onSubmit(projectDonations)}
            giftCard={giftCard}
            customCurrency={currency}
            projects={projectDonations}
            onSuccess={() => {
              onClose();
              setSuccessOpen(true);
              dispatch(removeAllProjectsEvent());
            }}
            onPaymentMethodChange={(e) => {
              if (e === 'goods' || e === 'volunteer') {
                setDonations(
                  projectDonations.map((e) => {
                    delete e.amount;
                    return e;
                  })
                );
                setShowAmountFields(false);
              } else {
                setShowAmountFields(true);
              }
            }}
            onSelectPaymentMethod={onSelectPaymentMethod}
          />
        </Grid>
      )}

      <InstructionsDialog open={instructionsOpen} onClose={() => setInstructionsOpen(false)} />

    </Grid>
  )
}

const InstructionsDialog = ({ open, onClose }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6" color='orange' >NOTE:</Typography>
          <Typography variant="h6">Please follow this steps to ensure your gift card will be sent to your recipient.</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography width='100%' fontWeight={600} sx={{ mb: 2 }}>1. Fill up and complete the payment form.</Typography>
        <img src="/images/gift-card-online-payment-step-1.png" alt="step-1" width='100%' style={{ border: '1px solid #0000000f', borderRadius: 16 }} />

        <Typography width='100%' fontWeight={600} sx={{ mb: 2, mt: 5 }}>2. After completing step 1, click on 'Back to Merchant' button.</Typography>
        <img src="/images/gift-card-online-payment-step-2.png" alt="step-2" width='100%' style={{ border: '1px solid #0000000f', borderRadius: 16 }} />

        <Typography width='100%' fontWeight={600} sx={{ mb: 2, mt: 5 }}>3. After step 2, you should see this page. It is important that you go through this step.</Typography>
        <img src="/images/gift-card-online-payment-step-3.png" alt="step-3" width='100%' style={{ border: '1px solid #0000000f', borderRadius: 16 }} />

        <Button sx={{ mt: 5 }} size="large" variant="contained" onClick={() => onClose()}>Got it!</Button>
      </DialogContent>
    </Dialog>
  )
}