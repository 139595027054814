import axios from 'axios';
import { CPO_API } from '../config';
import { handleError, handleResponse } from './reponse';

const CMS_URL = `${CPO_API}/project/cmss`;

const getCMS = () => axios.get(CMS_URL).then(handleResponse).catch(handleError);

const getHome = async () => {
    const result = await getCMS();
    return result.data.findLast(e => e.name === "home");
};

const getAboutUs = async () => {
    const result = await getCMS();
    return result.data.findLast(e => e.name === "aboutus");
};


const getFooterDetails = async () => {
    const result = await getCMS();
    return {
        footer: result.data.findLast(e => e.name === "footer") || null,
        contactus: result.data.findLast(e => e.name === "contactus") || null,
    };
};

export const cmsAPI  = {
    getHome,
    getAboutUs,
    getFooterDetails,
};
