import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Checkbox, Grid, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import PaymayaSdkClient from "paymaya-js-sdk";
import { BraintreePayPalButtons, PayPalButtons, PayPalHostedField, PayPalHostedFieldsProvider, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../../../components/hook-form";
import { CPO_URL, MAYA_KEY } from "../../../../../config";
import { DonationAPI } from "../../../../../services/donation.services";
import NoAmountModal from "../../NoAmountModal";
import { getPlan } from "../../../../../services/paypal.services";

const REDIRECT_URL = `${CPO_URL}/resultpage`;

export default function CardPaymentForm({ giverType, recurring, frequency, discretionary, projects, causes, disabled, currency, onSuccess, onSubmit, giftCard }) {

    const [noAmountOpen, setNoAmountOpen] = useState(false);
    const [isGettingPlanId, setGettingPlanId] = useState(false);

    const [isAnon, setIsAnon] = useState(false);
    const resolver = yupResolver(Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        phone: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
    }))

    const defaultValues = {
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
    }

    const methods = useForm({ defaultValues, resolver })

    const {
        reset,
        setError,
        handleSubmit,
        setValue,
        trigger,
        watch,
        clearErrors,
        formState: { errors, isSubmitting }
    } = methods;

    const values = watch();

    const onSubmitMaya = async (data) => {
        PaymayaSdkClient.init(MAYA_KEY, false);
        const amount = projects.reduce((prev, val) => prev + val.amount, 0);
        const noAmountProject = projects.filter(e => !e.amount);

        if (!amount || noAmountProject.length) {
            setNoAmountOpen(true);
            return;
        }

        const payload = createPayload({ discretionary, amount, formData: data, projects, causes, currency, giftCard });
        DonationAPI.submitDonationMaya(payload)
            .then((e) => {
                const refId = e.data.data.insertedId;
                const refDataParam = btoa(
                    JSON.stringify({
                        amount,
                        refId,
                        currency
                    })
                );

                const checkoutDetails = createCheckoutDetails({
                    amount,
                    data,
                    projects,
                    refDataParam,
                    currency,
                    requestReferenceNumber: e.data.data.insertedId
                });

                localStorage.setItem("checkoutDetails", JSON.stringify(checkoutDetails));

                onSubmit?.();

                PaymayaSdkClient.createCheckout(checkoutDetails);
            })
            .catch((e) => {
                alert("Encountered an error. Please try again.");
            });
    }

    const onSubmitPaypal = async (data) => {
        const amount = projects.reduce((prev, val) => prev + val.amount, 0);
        const noAmountProject = projects.filter(e => !e.amount);

        if (!amount || noAmountProject.length) {
            setNoAmountOpen(true);
        }

        setGettingPlanId(true);
        const payload = createPayload({ discretionary, amount, formData: data, projects, causes, frequency, currency });
        DonationAPI.submitDonationPaypal(payload)
            .then(async (e) => {
                const planId = await getPlanId();
                const refId = e.data.data.insertedId;
                setGettingPlanId(false);
                document.getElementById('paypal-button-container').innerHTML = '';
                const paypal = window.paypal;
                paypal.Buttons({
                    createSubscription: (data, actions) => {
                        return actions.subscription.create({
                            plan_id: planId.data,
                            custom_id: refId,
                        })
                    },
                    onApprove: (data, actions) => {
                        onSuccess();
                    }
                }).render('#paypal-button-container');
            });

    }

    const anonymous = {
        firstName: 'Anonymous',
        lastName: 'Donor',
        email: 'anonymousdonor@charityphilippines.org',
        phone: '09111111111'
    }

    useEffect(() => {
        if (isAnon) {
            reset(anonymous);
        } else {
            reset(defaultValues);
        }
    }, [isAnon])

    const getPlanId = () => {
        const amount = projects.reduce((prev, val) => prev + val.amount, 0);
        const noAmountProject = projects.filter(e => !e.amount);

        if (amount && !noAmountProject.length && frequency) {
            return getPlan({ giverType, projects, frequency, currency, discretionary, causes });
        }
    }

    return (
        <Box>
            <Typography fontWeight={700}>Card Payment</Typography>
            {/* <Typography>Donate via Bank Deposit, Bank Transfer, or Wire Transfer to:</Typography> */}
            <FormProvider methods={methods} onSubmit={handleSubmit(recurring ? onSubmitPaypal : onSubmitMaya)}>
                <Grid container spacing={2} direction='column'>
                    {
                        recurring ? <Box my={2} /> :
                            <Grid item container alignItems='center' mt={3} sx={{ cursor: 'pointer' }} onClick={() => setIsAnon(!isAnon)}>
                                <Grid item>
                                    <Checkbox checked={isAnon} onChange={() => setIsAnon(!isAnon)} />
                                </Grid>
                                <Grid item width='fit-content'>
                                    <Typography>Anonymous Donor</Typography>
                                </Grid>
                            </Grid>
                    }
                    {
                        isAnon ? null :
                            <Grid item container spacing={2} justifyContent='space-between'>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField autoFocus name='firstName' fullWidth label="First Name" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name='lastName' fullWidth label="Last Name" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name='email' fullWidth label="Email" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFTextField name='phone' fullWidth label="Phone Number" />
                                </Grid>
                            </Grid>
                    }
                </Grid>
                <Grid container justifyContent='center' py={5}>
                    <Box width={300}>
                        {
                            !recurring &&
                            <Button disabled={disabled} fullWidth variant="contained" size="large" type="submit">SUBMIT</Button>
                        }
                        {
                            recurring &&
                            <div id="paypal-button-container">
                                <LoadingButton loading={isGettingPlanId} fullWidth variant="contained" size="large" type="submit">PROCEED TO PAYMENT</LoadingButton>
                            </div>
                        }
                    </Box>
                </Grid>
            </FormProvider>
            <NoAmountModal open={noAmountOpen} onClose={() => setNoAmountOpen(false)} />
        </Box>
    )
}

const createPayload = ({ discretionary, amount, formData, projects, causes, currency, frequency, giftCard }) => ({
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    contactNumber: formData.phone,
    address: "",
    country: "",
    website: "",
    donationAmount: amount,
    currency,
    projects: projects.map((e, i) => ({
        id: e.id ?? e._id,
        name: e.name,
        amount: e.amount,
        charityId: e.charityDetails?.id ?? e.charityId,
        charityName: "CharityPhilippines.org",
    })),
    discretion: {
        isDiscretion: discretionary,
        causes,
        frequency,
    },
    giftCard
});

const createCheckoutDetails = ({ amount, data, projects, refDataParam, requestReferenceNumber, currency = 'PHP' }) => ({
    totalAmount: {
        value: amount,
        currency,
        details: {
            discount: 0,
            serviceCharge: 0,
            shippingFee: 0,
            subtotal: amount,
            tax: 0,
        },
    },
    buyer: {
        firstName: data.firstName,
        lastName: data.lastName,
        contact: {
            email: data.email,
            phone: data.phone,
        },
    },
    items: projects.map((e, i) => ({
        name: e.name,
        quantity: 1,
        code: e.id ? e.id?.toString() : e._id?.toString(),
        description: e.shortDescription,
        amount: {
            value: e.amount,
            details: {
                discount: 0,
                serviceCharge: 0,
                shippingFee: 0,
                subtotal: e.amount
            }
        },
        totalAmount: {
            value: e.amount,
            details: {
                discount: 0,
                serviceCharge: 0,
                shippingFee: 0,
                tax: 0,
                subtotal: e.amount
            }
        }
    })),
    redirectUrl: {
        success: `${REDIRECT_URL}/${refDataParam}/success?redirectToHome=true`,
        failure: `${REDIRECT_URL}/${refDataParam}/failed?redirectToHome=true`,
        cancel: `${REDIRECT_URL}/${refDataParam}/cancelled?redirectToHome=true`,
    },
    requestReferenceNumber,
});