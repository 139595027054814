// import axios from '../utils/axios';
import axios from 'axios';
import { handleResponse, handleError } from './reponse';
import { AcctApiAuth, AcctApiSecretKey, AcctApiUrl, CPO_API } from '../config';
import { ProjectAPI } from './projects.services';

const CryptoJS = require("crypto-js");

// const BASE_URL =  'https://prod-api.charityphilippines.org';
const BASE_URL = CPO_API; // 'https://dev-api.charityphilippines.org';
const IN_HOUSE_DONATION_URL = `${BASE_URL}/donation`;
const PAYMAYA_DONATION_URL = `${BASE_URL}/donation/paymaya`;
const PAYPAL_DONATION_URL = `${BASE_URL}/donation/paypal`;
const TRANSACTION_DONATION_URL = `${BASE_URL}/donation/transaction`;
const config = {
  headers: { 'content-type': 'multipart/form-data' }
}
const submitDonationInHouse = (donationType, data) => {
  const payload = setFormData(donationType, data);
  // return axios.post(IN_HOUSE_DONATION_URL,payload,config).then(handleResponse).catch(handleError);
  return axios.post(IN_HOUSE_DONATION_URL, payload, config);
};

const submitDonationMaya = (data) => {
  const encoded = btoa(encodeURIComponent(JSON.stringify(data)));
  const token = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.append('type', "PAYMAYA");
  formData.append('verificationCallbackUrl', `${BASE_URL}/verify`);
  formData.append('details', encoded);
  return axios.post(PAYMAYA_DONATION_URL, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      'authorization': `Bearer ${token}`
    }
  });
}

const submitDonationPaypal = (data) => {
  const token = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.append('type', "PAYPAL");
  formData.append('details', btoa(JSON.stringify(data)));
  return axios.post(PAYPAL_DONATION_URL, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      'authorization': `Bearer ${token}`
    }
  });
}

const postDonationTransaction = (data) => {

  const token = localStorage.getItem('accessToken');

  return axios.post(TRANSACTION_DONATION_URL, data, {
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });
}

const addAccountingDonation = async (checkoutDetails) => {

  const acctToken = await axios.post(`${AcctApiUrl}/api/auth_admin`, {}, { headers: { secret_key: AcctApiSecretKey } });

  const mop = await axios.get(`${AcctApiUrl}/api/getModeOfPayment`, {
    headers: {
      secret_key: AcctApiSecretKey,
      authorization: acctToken.data.access_token
    }
  });
  const categories = await axios.get(`${AcctApiUrl}/api/getCategory`, {
    headers: {
      secret_key: AcctApiSecretKey,
      authorization: acctToken.data.access_token
    }
  });
  const _mop = mop.data.find(e => e.description.toLowerCase().includes('paymaya')).id

  // eslint-disable-next-line no-restricted-syntax
  for (const project of checkoutDetails.items) {

    const projectDetails = await ProjectAPI.getProjectDetails(project.code); // eslint-disable-line no-await-in-loop

    const acctResponse = await axios.post(`${AcctApiUrl}/api/addDonation`, { // eslint-disable-line no-await-in-loop
      project_id: project.code,
      partner_id: projectDetails.data.charityId,
      donor_id: "",
      mode_of_payment_id: _mop,
      transaction_no: checkoutDetails.requestReferenceNumber,
      date: (new Date()).toISOString(),
      remarks: "",
      donation_source_id: "",
      amount: project.amount,
      location: "",
      category_id: categories.data.find(e => e.description.toLowerCase().includes('monetary')).id,
      description: project.description,
      status: 1
    }, {
      headers: {
        secret_key: AcctApiSecretKey,
        authorization: acctToken.data.access_token
      }
    })
  }

}

const setFormData = (donationType, data) => {
  const formData = new FormData();
  formData.append('type', donationType);
  formData.append('details', encodeFormData(JSON.stringify(data)));
  if (data.images && data.images.length > 0) {
    data.images.map((img) => {
      formData.append('attachment', img);
      return null;
    })

    data.imagesBase64.map((imgBase64, index) => {
      formData.append(`image-${index}`, imgBase64)
      return null;
    })
  }

  // new Response(formData).text().then(console.log)  to check form data content
  return formData;
}

const encodeFormData = (stringifyData) => {
  const encodeUTF8 = CryptoJS.enc.Utf8.parse(stringifyData);
  const encodedData = CryptoJS.enc.Base64.stringify(encodeUTF8);
  return encodedData;
}

export const DonationAPI = {
  submitDonationInHouse,
  submitDonationMaya,
  submitDonationPaypal,
  postDonationTransaction,
  addAccountingDonation
}