import { dispatch } from "../store";

const { createSlice } = require("@reduxjs/toolkit")

const initialState = {}

const slice = createSlice({
    name: 'giftCard',
    initialState,
    reducers: {
        setGiftCard(state, action) {
            state.giftCard = action.payload;
        }
    }
})

export default slice.reducer;

export function setGiftCardDetails(data) {
    return () => {
        dispatch(slice.actions.setGiftCard(data));
    }
}

export function resetGiftCardDetails() {
    return () => {
        dispatch(slice.actions.setGiftCard(initialState));
    }
}