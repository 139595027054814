import { createSlice } from '@reduxjs/toolkit';
import { CharityAPI } from '../../services/charity.services';
// utils
import axios from '../../utils/axios';
import { CPO_API } from '../../config';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const LS_KEY_DONATION = 'donate-projects';
const initProjects = window.localStorage.getItem(LS_KEY_DONATION)
  ? JSON.parse(window.localStorage.getItem(LS_KEY_DONATION))
  : [];
const initialState = {
  isLoading: false,
  error: null,
  projects: initProjects,
  fileAttachments: [],
  donations: [],
  donation: null,
  sortBy: '',
  filters: {
    category: [],
    isSpecialdonations: false,
    searchBy: 'name',
    searchKey: '',
  },
  isModalOpen: false,
  isGiftCardOpen: false,
};

const slice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addProject(state, action) {
      const project = action.payload;
      state.isLoading = false;
      if (!state.projects.find(p => p._id === project._id)) {
        state.projects = [...state.projects, { ...project, amount: null }];
        window.localStorage.setItem(LS_KEY_DONATION, JSON.stringify(state.projects))
      }

    },

    // removeProject(state, action){
    //   const projectId = action.payload;
    //   state.isLoading = false;
    //   state.projects = state.projects.filter(p => p._id !== projectId);
    //   window.localStorage.setItem(LS_KEY_DONATION,JSON.stringify(state.projects));
    // },

    removeProject(state, action) {
      const projectId = action.payload;
      state.isLoading = false;
      state.projects = state.projects.filter((p) => p._id !== projectId);
      window.localStorage.setItem(LS_KEY_DONATION, JSON.stringify(state.projects));
    },

    removeAllProjects(state) {
      state.projects = [];
      window.localStorage.removeItem(LS_KEY_DONATION);
    },

    setProjectAmount(state, action) {
      const { _id, amount } = action.payload;
      state.projects.find(p => p._id === _id).amount = amount;
    },

    setFileAttachments(state, action) {
      state.fileAttachments = action.payload;
    },

    getdonationsSuccess(state, action) {
      state.isLoading = false;
      state.donations = action.payload;
    },

    getdonationsDetailsSuccess(state, action) {
      state.isLoading = false;
      state.donation = action.payload;
    },

    sortdonationsBy(state, action) {
      state.sortBy = action.payload;
    },

    filterdonations(state, action) {
      state.filters.category = action.payload.category;
      state.filters.isSpecialdonations = action.payload.isSpecialdonations;
      state.filters.searchBy = action.payload.searchBy;
      state.filters.searchKey = action.payload.searchKey;
    },

    setModal(state, action) {
      state.isModalOpen = action.payload;
    },

    setGiftCardModal(state, action) {
      state.isGiftCardOpen = action.payload;
    }
  },
});

const getCharityDetails = async (charityId) => {
  const token = localStorage.getItem('accessToken');
  const data = await axios.get(`${CPO_API}/user/info?_id=${charityId}`);
  return data.data.data;
  // if(charityId){
  //   return data.find((c)=> c.id === charityId)[0];
  // }
}

// Reducer
export default slice.reducer;

// Actions
export const { addProject, removeAllProjects, removeProject, setProjectAmount, setFileAttachments } = slice.actions;

// ----------------------------------------------------------------------

export function addProjectEvent(project) {

  return async () => {
    try {
      // const charity = await getCharityDetails(project.charityId);
      // project.charityName = charity.firstName
      // project.charityDetails = charity
      dispatch(slice.actions.addProject(project));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function removeProjectEvent(id) {
  return async () => {
    try {
      dispatch(slice.actions.removeProject(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function removeAllProjectsEvent() {
  return async () => {
    try {
      dispatch(slice.actions.removeAllProjects());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setProjectAmountEvent(project, amount) {
  return async () => {
    try {
      dispatch(slice.actions.setProjectAmount({ ...project, amount }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setFileAttachmentsEvent(files) {
  return async () => {
    try {
      dispatch(slice.actions.setFileAttachments(files));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// =======================================================================

export function getDonations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CPO_API}/donation?skip=0&limit=0`);
      dispatch(slice.actions.getdonationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDonationsByEmail(email) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CPO_API}/donation?email=${email}&paymentStatus=SUCCESS,PENDING,PAYMENT_SUCCESS`);
      dispatch(slice.actions.getdonationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDonation(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CPO_API}/donation?donationId=${id}`);
      dispatch(slice.actions.getdonationsDetailsSuccess(response.data.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDonationByCharity(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CPO_API}/donation/?charityId=${id}`);
      dispatch(slice.actions.getdonationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDonationByProject(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CPO_API}/donation/?projectId=${id}&skip=0&limit=0`);
      dispatch(slice.actions.getdonationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setBasketModal(open) {
  return async () => {
    dispatch(slice.actions.setModal(open))
  }
}

export function setGiftCardModal(open) {
  return async () => {
    dispatch(slice.actions.setGiftCardModal(open));
  }
}